
import { defineComponent } from "vue";

export default defineComponent({
    name: "Notes",
    props: {
        canvas: {
            required: true,
        }
    },
    data() {
        const artboardSize = 1280;
        const resolution = 64;
        const seedHue = 300;
        return {
          shape: "dot",
          artboardSize: artboardSize,
          artboard: null as any,
          interval: null as any,
          resolution: resolution,
          colorScale: 100,
          gradients: {} as any,
          memory: {} as any,
          a: 6,
          b: -15,
          c: 10,
          d: 5,
          e: 4,
          f: 3,
          hseed: 180,
          hradius: 360,
          lcenter: 0,
          lradius: 0,
          scenter: 99,
          sradius: 1,
          acenter: 80,
          aradius: 100,
        };
      },
      mounted: function () {
        if(this.canvas != null) {
            this.artboard = (this.canvas as any).getContext("2d");
            this.artboard.fillStyle = "#262626";
        }
      },
      watch: {
        artboardSize: function(newSize: number) {
            this.$emit("update-size", newSize);
        },
        canvas: function(newCanvas: any) {
            if(this.canvas != null) {
                this.artboard = (this.canvas as any).getContext("2d");
                this.artboard.fillStyle = "#262626";
            }
        }
      },
      methods: {
        start: function () {
          this.gradients = {};
          this.memory = {};
          //this.interval = setInterval(() => {
          this.artboard.clearRect(0, 0, this.artboardSize, this.artboardSize);
          this.draw();
          //}, 1000);
        },
        draw: function (): any {
          const pixelSize = this.artboardSize / this.resolution;
          const numPixels = 1 / this.resolution;
          for (let y = 0; y < 1; y += numPixels) {
            for (let x = 0; x < 1; x += numPixels) {
              const h =
                this.getPerlin("h", x, y) * Number(2 * this.hradius) +
                Number(this.hseed) -
                (-Number(this.hradius) % 360);
              const s =
                this.getPerlin("s", x, y) * Number(2 * this.sradius) +
                Number(this.scenter) -
                Number(this.sradius);
              let l =
                this.getPerlin("l", x, y) * Number(2 * this.lradius) +
                Number(this.lcenter) -
                Number(this.lradius);
              let a =
                this.getPerlin("a", x, y) * Number(2 * this.aradius) +
                Number(this.acenter) -
                Number(this.aradius);
              this.artboard.fillStyle = `hsl(${h}, ${s}%, ${l}%, ${a / 100})`;
              if (this.shape == "dot") {
                this.artboard.beginPath();
                this.artboard.arc(
                  x * this.artboardSize,
                  y * this.artboardSize,
                  pixelSize * 0.3,
                  0,
                  2 * Math.PI,
                  false
                );
                this.artboard.fill();
              } else {
                this.artboard.fillRect(
                  x * this.artboardSize,
                  y * this.artboardSize,
                  pixelSize,
                  pixelSize
                );
              }
            }
          }
        },
        getPerlin: function (n: string, x: number, y: number) {
          if (!this.memory[n]) this.memory[n] = {};
          if (this.memory[n][x] && this.memory[n][x][y])
            return this.memory[n][x][y];
          if (!this.memory[n][x]) this.memory[n][x] = {};
          let xf = Math.floor(x);
          let yf = Math.floor(y);
          //interpolate
          let tl = this.dotProductGrid(n, x, y, xf, yf);
          let tr = this.dotProductGrid(n, x, y, xf + 1, yf);
          let bl = this.dotProductGrid(n, x, y, xf, yf + 1);
          let br = this.dotProductGrid(n, x, y, xf + 1, yf + 1);
          let xt = this.interpolate(x - xf, tl, tr);
          let xb = this.interpolate(x - xf, bl, br);
          let v = Math.abs(this.interpolate(y - yf, xt, xb));
          this.memory[n][x][y] = v;
          return v;
        },
        dotProductGrid: function (
          n: string,
          x: number,
          y: number,
          vx: number,
          vy: number
        ) {
          let g_vect;
          let d_vect = { x: x - vx, y: y - vy };
          if (!this.gradients[n]) this.gradients[n] = {};
          if (!this.gradients[n][vx]) this.gradients[n][vx] = {};
          if (this.gradients[n][vx] && this.gradients[n][vx][vy]) {
            g_vect = this.gradients[n][vx][vy];
          } else {
            g_vect = this.getRandomVector();
            this.gradients[n][vx][vy] = g_vect;
          }
          return d_vect.x * g_vect.x + d_vect.y * g_vect.y;
        },
        smoothStep: function (x: number) {
          return this.a * x ** this.d + this.b * x ** this.e + this.c * x ** this.f;
        },
        interpolate: function (x: number, a: number, b: number) {
          return a + this.smoothStep(x) * (b - a);
        },
        getRandomVector: function () {
          let theta = Math.random() * 2 * Math.PI;
          return { x: Math.cos(theta), y: Math.sin(theta) };
        },
   },
})
