import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overflow-hidden flex items-center justify-center" }
const _hoisted_2 = {
  style: {"max-width":"100% flex items-center justify-center"},
  class: "overflow-hidden"
}
const _hoisted_3 = ["width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notes = _resolveComponent("Notes")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("article", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("canvas", {
          width: _ctx.artboardSize,
          height: _ctx.artboardSize,
          ref: "canvas"
        }, null, 8, _hoisted_3)
      ])
    ]),
    _createVNode(_component_Notes, {
      canvas: _ctx.canvas,
      onUpdateSize: _ctx.handleUpdateSize
    }, null, 8, ["canvas", "onUpdateSize"])
  ]))
}